@use "@angular/material" as mat;

@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

// Add base app styles
@import "./theme/app-defaults.scss";
@import "./theme/splash.scss";
