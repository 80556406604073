@include mat.core();
@import "@angular/material/theming";

// Adjust default font size based on screen width
* {
  font-size: 16px;
  letter-spacing: -0.4px;

  @media only screen and (min-device-width: 320px) and (max-device-width: 374px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 12px;
  }

  // Remove 1px border from body
  --border: none !important;

  // Used for markdown logic to force innerHTML to indirectly support font-size
  big {
    font-size: 1rem !important;
    vertical-align: top;
  }
  sup {
    font-size: 0.9rem !important;
    font-weight: normal !important;
    top: 10px !important;
    vertical-align: top;
  }
  small {
    font-size: 0.75rem !important;
    vertical-align: inherit !important;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: linear-gradient(var(--ion-color-primary-dark), var(--ion-color-primary));
}

ul {
  color: var(--ion-color-dark);
}

// IONIC COMPONENT THEMES

ion-content {
  --background: var(--ion-color-background);
}

ion-back-button {
  --color: var(--ion-color-always-white);
}

ion-button {
  text-transform: none;
  border-radius: 0.625rem;
}

ion-card {
  background: var(--ion-color-light);
  margin: 0.5rem;
  padding: 0;
  border-radius: 0.625rem;
  overflow: hidden;
}

ion-card-header {
  font-size: 1.1rem;
  font-weight: bold;
  --color: var(--ion-color-secondary-contrast);
  --background: var(--ion-color-secondary);
  width: 100%;
  text-align: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

ion-card-content {
  padding: 1rem;
}

ion-icon {
  color: var(--ion-color-dark);
}

ion-input {
  color: var(--ion-color-dark) !important;
}

// Hide spinner from generic input controls
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

ion-item {
  --padding-start: 1rem;
  --padding-end: 1rem;
  --color: var(--ion-color-light);
  --ion-background-color: var(--ion-color-light);
  --background-focused: var(--ion-color-light);
  --ion-item-border-color: var(--ion-color-gray-50);
}

ion-label {
  color: var(--ion-color-dark) !important;
  white-space: normal !important;
}

// Standardize size and width of stacked labels
.label-stacked.sc-ion-label-md-h {
  font-size: 0.8rem !important;
  -webkit-transform: translateY(30%) !important;
  transform: translateY(30%) !important;
}

ion-list {
  background: var(--ion-color-light);
}

ion-loading.loading-message {
  .loading-wrapper {
    border-radius: 0.5rem !important;
  }
}

ion-modal {
  --max-height: 90% !important;
  --width: 34rem !important;
  --max-width: 90% !important;
  --border-radius: 1rem;
}

.max-width-modal::part(content) {
  --max-width: 50rem !important;
}

.popover-on-top {
  z-index: 99999 !important;
}

ion-radio {
  --color-checked: var(--ion-color-dark);
}

ion-searchbar {
  padding: 0 !important;
  --box-shadow: none !important;
}

ion-searchbar .searchbar-input-container .searchbar-input {
  font-size: 1rem;
  height: 36px !important;
  --border-radius: 6px;
  --placeholder-color: var(--ion-color-always-dark);
  --background: var(--ion-color-always-light);
  padding-inline-start: 3rem !important;
}

ion-searchbar .searchbar-search-icon.sc-ion-searchbar-md {
  top: 7px;
}

ion-searchbar .searchbar-clear-icon {
  color: var(--ion-color-always-dark);
}

ion-segment {
  --background: var(--ion-color-segment);
}

ion-segment-button {
  min-width: 0px;
  color: var(--ion-color-always-black);
  --indicator-color: var(--ion-color-always-white);
  --background: var(--ion-color-segment-button);

  ion-label {
    color: var(--ion-color-always-black) !important;
  }
}

ion-select {
  color: var(--ion-color-dark);
}

ion-tab-bar {
  --background: var(--ion-color-primary);
  --border: 0;
}

ion-tab-button {
  --color: var(--ion-color-primary);
  --color-selected: var(--ion-color-primary-dark);
}

ion-title {
  color: var(--ion-color-primary-contrast);
  letter-spacing: 0.4px;
  padding-inline: 1rem;
  font-size: 1.2rem;
}

ion-toggle {
  padding-left: 0rem;
  --background-checked: #c6e6e1;
  --handle-background-checked: green; // var(--ion-color-secondary);
}

ion-toolbar {
  --color: var(--ion-color-primary-contrast);
}

// CUSTOM COLORS

.ion-color-neutral {
  --ion-color-base: var(--ion-color-neutral) !important;
}

// MATERIAL COMPONENTS

.mat-accordion > .mat-expansion-panel {
  margin: 1rem 0 1rem 0;
}

.mat-expansion-panel .mat-expansion-panel-header {
  height: 2.5rem;
  background: var(--ion-color-gray-05) !important;
}

.mat-expansion-panel .mat-expansion-panel-header.mat-expanded {
  height: 2.5rem;
}

.mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
  background: var(--ion-color-light) !important;
}

.cdk-overlay-pane {
  &.mat-tooltip-panel {
    .mat-tooltip {
      background-color: rgba(0, 0, 0, 0.8);
      font-size: 0.8rem;
    }
  }
}

.mat-tooltip {
  white-space: pre-line;
  max-width: 15rem;
}

// SWEETALERT2

.custom-loader {
  border: 0.5rem solid #f3f3f3;
  border-top: 0.5rem solid #a30000;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 2s linear infinite;
}

.custom-left-justify {
  text-align: left !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.swal2-modal {
  $icon-size: 0.65rem;
  padding-left: 0px;
  padding-right: 0px;
  background-color: rgba(255, 255, 255, 1) !important;
  border: 3px solid var(--ion-color-accent) !important;
  border-radius: 1rem !important;

  .swal2-title {
    font-size: 1.3rem !important;
  }

  // Shrink built-in animated icons

  .swal2-icon {
    font-size: $icon-size !important;
  }

  .swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    font-size: $icon-size !important;
  }

  .swal2-icon.swal2-success [class^="swal2-success-line-tip"] {
    font-size: $icon-size !important;
  }

  .swal2-icon.swal2-success [class^="swal2-success-circular-line-left"] {
    font-size: $icon-size !important;
  }

  .swal2-icon.swal2-success [class^="swal2-success-line-long"] {
    font-size: $icon-size !important;
  }

  .swal2-icon.swal2-success [class^="swal2-success-ring"] {
    font-size: $icon-size !important;
  }

  .swal2-icon.swal2-success [class^="swal2-success-fix"] {
    font-size: $icon-size !important;
  }

  .swal2-icon.swal2-success [class^="swal2-success-circular-line-right"] {
    font-size: $icon-size !important;
  }
}

.swal2-container {
  width: auto !important;
  background: rgba(255, 255, 255, 0.8);
}

.swal2-loading {
  max-width: 18rem !important;
}

.swal2-html-container b {
  font-size: 18px !important;
}

.swal2-styled.swal2-cancel {
  height: 2.4rem;
  padding: 0.6375rem 1rem !important;
  color: var(--ion-color-accent) !important;
  background-color: #ffffff !important;
  border-width: 2px !important;
  border-color: var(--ion-color-accent) !important;
  border-style: solid !important;
}

// ANGULAR MATERIAL THEMES

$mat-brand-primary: (
  50: #e5e5e5,
  100: #bebebe,
  200: #939393,
  300: #676767,
  400: #474747,
  500: #262626,
  600: #222222,
  700: #1c1c1c,
  800: #171717,
  900: #0d0d0d,
  A100: #eb6969,
  A200: #e53c3c,
  A400: #ee0000,
  A700: #d40000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff
  )
);

$mat-brand-primary-dark: (
  50: #eeeeee,
  100: #d5d5d5,
  200: #b9b9b9,
  300: #9d9d9d,
  400: #888888,
  500: #737373,
  600: #6b6b6b,
  700: #606060,
  800: #565656,
  900: #434343,
  A100: #f6acac,
  A200: #f17e7e,
  A400: #ff3d3d,
  A700: #ff2424,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff
  )
);

$mat-brand-secondary: (
  50: #e0f1f1,
  100: #b3dcdc,
  200: #80c5c5,
  300: #4daeae,
  400: #269c9c,
  500: #008b8b,
  600: #008383,
  700: #007878,
  800: #006e6e,
  900: #005b5b,
  A100: #8cffff,
  A200: #59ffff,
  A400: #26ffff,
  A700: #0dffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

// Define light material theme
$brand-primary: mat.define-palette($mat-brand-primary, 500);
$brand-primary-dark: mat.define-palette($mat-brand-primary-dark, 500);
$brand-accent: mat.define-palette($mat-brand-secondary, 500);
$brand-typography: mat.define-typography-config(
  $font-family: '"Open Sans", sans-serif'
);
$mat-theme-light: mat.define-light-theme(
  (
    color: (
      primary: $brand-primary,
      accent: $brand-accent
    ),
    typography: $brand-typography
  )
);

$mat-theme-dark: mat.define-dark-theme($brand-primary-dark, $brand-accent);
@include mat.all-component-themes($mat-theme-light);

body.dark {
  @include mat.all-component-colors($mat-theme-dark);
}

// Include the theme styles for only specified components.
@include mat.core-color($mat-theme-light);
@include mat.datepicker-color($mat-theme-light);
@include mat.menu-color($mat-theme-light);
@include mat.select-color($mat-theme-light);
@include mat.sort-color($mat-theme-light);
@include mat.table-color($mat-theme-light);
@include mat.tabs-color($mat-theme-light);

// ANGULAR MATERIAL OVERRIDES
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--ion-color-dark) !important;
}

.mat-chip.mat-standard-chip {
  color: var(--ion-color-always-dark) !important;
  background: linear-gradient(var(--ion-color-always-light-light), var(--ion-color-always-light));
}

.mat-chip-selected.mat-primary {
  color: var(--ion-color-secondary-contrast) !important;
  background: linear-gradient(var(--ion-color-secondary-light), var(--ion-color-secondary));
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--ion-color-dark) !important;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--ion-color-dark) !important;
}

.mat-table {
  background-color: var(--ion-color-light) !important;
}

.mat-paginator {
  background-color: var(--ion-color-light) !important;

  .mat-paginator-container {
    min-height: 40px !important;
  }
}

.mat-sort-header-arrow {
  color: var(--ion-color-accent-contrast) !important;
}

.mat-app-background {
  background-color: var(--ion-color-light) !important;
}
