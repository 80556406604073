:root {
  --app-narrow-margin: 12px;
  --app-fair-margin: 16px;
  --app-broad-margin: 20px;

  --app-narrow-radius: 4px;
  --app-fair-radius: 8px;
  --app-broad-radius: 12px;

  --ion-font-family: "Open Sans", sans-serif;
  --ion-margin: var(--app-fair-margin);

  --ion-color-primary: #8b0000;
  --ion-color-primary-dark: #660000;
  --ion-color-primary-contrast: #ffffff;

  --ion-color-accent: #008b8b;
  --ion-color-accent-light: #00a3a3;
  --ion-color-accent-dark: #006f6f;
  --ion-color-accent-contrast: #ffffff;

  --ion-color-secondary: #262626;
  --ion-color-secondary-light: #404040;
  --ion-color-secondary-dark: #0d0d0d;
  --ion-color-secondary-contrast: #ffffff;

  --ion-color-tertiary: #d11345;
  --ion-color-tertiary-contrast: #ffffff;

  --ion-color-warning: #ffa726;
  --ion-color-warning-contrast: #000000;

  --ion-color-danger: #e12d39;

  --ion-color-dark: #262626;
  --ion-color-dark-light: #0d0d0d;
  --ion-color-dark-contrast: #ffffff;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-shade-rgb: 224, 224, 244;

  --ion-color-positive: #4daf1b;
  --ion-color-positive-light: #5dd421;
  --ion-color-positive-arrow: #80e34f;
  --ion-color-negative: #e12d39;
  --ion-color-negative-background: #e6e6e6;
  --ion-color-negative-arrow: #ff4652;
  --ion-color-neutral: #f4f4f4;
  --ion-color-drilldown: #ffffdc;

  --ion-color-black: #000000;
  --ion-color-gray-95: #0d0d0d;
  --ion-color-gray-90: #1a1a1a;
  --ion-color-gray-85: #262626;
  --ion-color-gray-80: #333333;
  --ion-color-gray-75: #404040;
  --ion-color-gray-70: #4d4d4d;
  --ion-color-gray-65: #595959;
  --ion-color-gray-60: #666666;
  --ion-color-gray-55: #737373;
  --ion-color-gray-50: #808080;
  --ion-color-gray-45: #8c8c8c;
  --ion-color-gray-40: #999999;
  --ion-color-gray-35: #a6a6a6;
  --ion-color-gray-30: #b3b3b3;
  --ion-color-gray-25: #bfbfbf;
  --ion-color-gray-20: #cccccc;
  --ion-color-gray-15: #d9d9d9;
  --ion-color-gray-10: #e6e6e6;
  --ion-color-gray-05: #f2f2f2;
  --ion-color-white: #ffffff;

  --ion-color-segment: #f2f2f2;
  --ion-color-segment-light: #ffffff;
  --ion-color-segment-button: #f2f2f2;
  --ion-color-calendar-background: #ffffff;
  --ion-color-background: #f2f2f2;
  --ion-color-background-light: #fbfbfb;
  --ion-color-divider: #c8c8c8;

  --ion-background-color: var(--ion-color-light);
  --ion-background-color-rgb: var(--ion-color-light-rgb);

  --ion-tab-bar-background: var(--ion-color-light);
  --ion-tab-bar-border-color: var(--ion-color-primary);
  --ion-tab-bar-color-activated: var(--ion-color-primary);
  --ion-tab-bar-color: var(--ion-color-tertiary);

  --ion-color-always-black: #000000;
  --ion-color-always-dark: #262626;
  --ion-color-always-light: #ededed;
  --ion-color-always-light-light: #f2f2f2;
  --ion-color-always-white: #ffffff;

  --ion-color-facebook: #4267b2;
  --ion-color-google: #4285f4;
}

/** Dark Mode Colors **/

body.dark {
  --ion-color-accent: #00a3a3;
  --ion-color-accent-light: #00c4c4;
  --ion-color-accent-dark: #008b8b;
  --ion-color-accent-contrast: #ffffff;

  --ion-color-secondary: #737373;
  --ion-color-secondary-light: #666666;
  --ion-color-secondary-contrast: #ffffff;

  --ion-color-tertiary: #d11345;
  --ion-color-tertiary-contrast: #ffffff;

  --ion-color-warning: #ffce00;
  --ion-color-warning-contrast: #000000;

  --ion-color-dark: #ffffff;
  --ion-color-dark-light: #e6e6e6;
  --ion-color-dark-contrast: #000000;

  --ion-color-light: #1f1f1f;
  --ion-color-light-rgb: 18, 18, 18;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1b1b1b;
  --ion-color-light-shade-rgb: 27, 27, 27;

  --ion-color-black: #ffffff;
  --ion-color-gray-95: #f2f2f2;
  --ion-color-gray-90: #e6e6e6;
  --ion-color-gray-85: #d9d9d9;
  --ion-color-gray-80: #cccccc;
  --ion-color-gray-75: #bfbfbf;
  --ion-color-gray-70: #b3b3b3;
  --ion-color-gray-65: #a6a6a6;
  --ion-color-gray-60: #999999;
  --ion-color-gray-55: #8c8c8c;
  --ion-color-gray-50: #808080;
  --ion-color-gray-45: #737373;
  --ion-color-gray-40: #666666;
  --ion-color-gray-35: #595959;
  --ion-color-gray-30: #4d4d4d;
  --ion-color-gray-25: #404040;
  --ion-color-gray-20: #333333;
  --ion-color-gray-15: #262626;
  --ion-color-gray-10: #1a1a1a;
  --ion-color-gray-05: #0d0d0d;
  --ion-color-white: #000000;

  --ion-color-segment: #737373;
  --ion-color-segment-light: #666666;
  --ion-color-segment-button: #cccccc;
  --ion-color-calendar-background: #424242;
  --ion-color-background: #424242;
  --ion-color-background-light: #4d4d4d;
  --ion-color-divider: #999999;

  --ion-background-color: var(--ion-color-light);
  --ion-background-color-rgb: var(--ion-color-light-rgb);
  --ion-tab-bar-background: var(--ion-color-light);
  --ion-tab-bar-border-color: var(--ion-color-primary);
  --ion-tab-bar-color-activated: var(--ion-color-primary);
  --ion-tab-bar-color: var(--ion-color-tertiary);
}
